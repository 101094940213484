<template>
    <div id="pdlWrap">
        <loader v-show="loading"></loader>
        <div v-show="!loading">
            <div class="layoutBorder"
                 v-if="formStatus == 0 && formStep >= 0"
                 v-bind:class="{readOnly:formStep > 0}"
                 ref="formAlign">
            <div class="layoutHeader">
                <h4>Получить займ</h4>
                <p v-if="formErrMsg.length > 0" class="formGlobalErr">{{formErrMsg}}</p>
            </div>
            <div class="layoutBody">
                <div id="formStep1">
                    <div class="subBox">
                        <div v-if="false">
                            <p>Выберите период:</p>
                            <div id="pdlPeriod">
                                <div>
                                    <label>
                                        <input type="radio" v-model="forSend.ProductType" value="O">
                                        Одна неделя</label>
                                </div>
                                <div>
                                    <label>
                                        <input type="radio" v-model="forSend.ProductType" value="Т">
                                        Две недели</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>Выберите сумму займа: <span
                              class="bigNumber">{{forSend.selectedSumm | moneyIntlFormat}}</span></p>
                            <range
                              :rangeMin="minLimit"
                              :rangeMax="pdlLimit"
                              :rangeStep="1000"
                              v-model="forSend.selectedSumm"
                              ref="range"
                            ></range>
                            <div class="limitRange">
                                <p>От {{minLimit | moneyIntlFormat}}</p>
                                <p> до {{pdlLimit | moneyIntlFormat}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="subBox">
                        <p>Ваш платеж составит:</p>
                        <p class="bigNumber">{{computedPay.FixedPay | moneyIntlFormat}}</p>
                    </div>
                </div>


            </div>
                <div class="layoutFooter btnIsset" v-if="formStep == 0">
                    <div>
                        <ul>
                            <li>
                                <!--                                <checkBox-->
                                <!--                                  :label="'Проставлением отметки, я подтверждаю следующее:'"-->
                                <!--                                  :name="'isAgree'"-->
                                <!--                                  v-model="dealInfo.pod_ft"-->
                                <!--                                ></checkBox>-->
                                <div>
                                    <input type="checkbox" checked="checked" v-model="dealInfo.pod_ft">
                                    <p v-bind:class="{errMsg:!dealInfo.pod_ft}">Проставлением отметки, я подтверждаю
                                        следующее:</p>
                                </div>

                            </li>
                            <li>
                                <div>
                                    <input type="checkbox"
                                           v-model="dealInfo.pod_ft1">
                                    <p>Я не являюсь лицом или родственником любого из лиц, перечисленных ниже:</p>
                                </div>
                                <ul>
                                    <li>

                                        <p>Лицом, на которое возложено или было возложено ранее (с момента сложения
                                            полномочий прошло менее 1 года) исполнение важных государственных функций, в
                                            частности: - Главой государства (в том числе правящей королевской династии)
                                            или правительства; - Министром, его заместителем или помощником; - Высшим
                                            правительственным чиновником; - Должностным лицом судебных органов власти
                                            (Верховный, Конституционный суд); - Государственным прокурором и его
                                            заместителем; - Высшим военным чиновником; - Руководителем или членом Совета
                                            директоров Национальных Банков; - Послом; - Руководителем государственных
                                            корпораций; - Членом Парламента или иного законодательного органа.</p>

                                    </li>
                                    <li>

                                        <p>Лицом, облеченным общественным доверием, в частности: - Руководителем,
                                            заместителем руководителя международных организаций (ООН, ОЭСР, ОПЕК,
                                            Олимпийский комитет, Всемирный Банк и т.д.); - Членом Европарламента; -
                                            Руководителем или членом международных судебных организаций (Суд по правам
                                            человека, Гаагский трибунал и др.).</p>

                                    </li>
                                    <li>

                                        <p>Должностным лицом публичной международной организации, т.е. лицом,
                                            уполномоченным такой организацией действовать от ее имени</p>

                                    </li>
                                    <li>

                                        <p> Лицом, замещающим (занимающим) одну из перечисленных должностей: -
                                            государственную должность Российской Федерации; - должность члена Совета
                                            директоров Центрального банка Российской Федерации; - должность федеральной
                                            государственной службы, назначение на которую и освобождение от которой
                                            осуществляются Президентом Российской Федерации или Правительством
                                            Российской Федерации; - должность в Центральном банке Российской Федерации;
                                            - должность в государственной корпорации; - должности в иных организациях,
                                            созданных Российской Федерацией на основании федеральных законов, включенные
                                            в перечни должностей, определяемые Президентом Российской Федерации</p>

                                    </li>
                                    <li>

                                        <p>Родственником вышеуказанной категории лиц, в частности: - Супругом; -
                                            Родственником по прямой восходящей и нисходящей линии (родителями и детьми,
                                            дедушкой, бабушкой и внуками); - Полнородным или не полнородным (имеющими
                                            общих отца или мать) братом или сестрой, усыновителем или усыновленным</p>

                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div>
                                    <input type="checkbox" v-model="dealInfo.pod_ft2">
                                    <p>я действую к собственной выгоде. Иные лица, к выгоде которого я действую
                                        (выгодоприобретатель), отсутствуют. </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <input type="checkbox" v-model="dealInfo.pod_ft3">
                                    <p>лица, прямо или косвенно контролирующие мои действия, в том числе имеющие
                                        возможность определять решения, принимаемые мной, отсутствуют</p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <input type="checkbox" v-model="dealInfo.pod_ft4">
                                    <p>лица, прямо или косвенно контролирующие мои действия, в том числе имеющие
                                        возможность определять решения, принимаемые мной, отсутствуют</p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <input type="checkbox" v-model="dealInfo.pod_ft5">
                                    <p>С правом Кредитора уступить третьим лицам права(требования) по договору
                                        согласен</p>
                                </div>
                            </li>
                        </ul>
                        <!--                        <p><i>УВАЖАЕМЫЙ ЗАЕМЩИК. В СЛУЧАЕ, ЕСЛИ ВАШИ ДЕЙСТВИЯ КОНТРОЛИРУЮТСЯ ИНЫМИ ЛИЦАМИ, ВЫ ПРЕДСТАВЛЯЕТЕ ИНТЕРЕСЫ ИНОГО ЛИЦА И (ИЛИ) ДЕЙСТВУЮТЕ К ВЫГОДЕ ИНОГО ЛИЦА, ВАМ НЕОБХОДИМО ЗАПОЛНИТЬ АНКЕТУ (ТУТ ССЫЛКА НА АНКЕТУ).</i></p>-->
                    </div>
                    <div id="withOutInsurance">
                          <p v-on:click="insuranceOff">продолжить без страховки</p>
                    </div>
                        <button class="submitButton" v-on:click.prevent="fromStep(0)">Продолжить со страховкой</button>
<!--                    <button class="submitButton" v-on:click.prevent="insuranceOff"-->
<!--                            v-bind:class="{active:!dealInfo.pod_ft}">Продолжить-->
<!--                    </button>-->
            </div>
        </div>

            <div class="layoutBorder"
                 v-if="formStatus == 0 && formStep >= 1"
                 v-bind:class="{readOnly:formStep > 1}"
                 ref="formAlign">
                <div class="layoutHeader">
                    <h4>Проверка ИНН</h4>
                    <p v-if="formErrMsg.length > 0" class="formGlobalErr">{{formErrMsg}}</p>
                </div>
                <div class="layoutBody">
                    <baseInput
                      :label="'Инн'"
                      v-model="forSend.Inn"
                      :errMsg="err.Inn"
                    ></baseInput>
                </div>
                <div class="layoutFooter btnIsset" v-if="formStep == 1">
                    <p>Проверьте правильность ввода данных. Если номер введен не верно, исправьте</p>
                    <button class="submitButton" v-on:click.prevent="fromStep(1)">Подтвердить
                    </button>
                </div>
            </div>

            <div class="layoutBorder"
                 v-if="formStatus == 0 && formStep >= 2"
                 ref="formAlign"
                 v-bind:class="{readOnly:formStep > 2}"
            >
            <div class="layoutHeader">
                <h4>Укажите недостающую информацию</h4>
                <p v-if="formErrMsg.length > 0" class="formGlobalErr">{{formErrMsg}}</p>
            </div>
            <div class="layoutBody">
                <div class="formCont">
                    <div class='flex'>
                        <fmSelectRef :refs="'TYPE_OF_EMPLOYMENT'"
                                     :err="err.TYPE_OF_EMPLOYMENT"
                                     v-model="forSend.TYPE_OF_EMPLOYMENT"></fmSelectRef>


                        <fmSelectRef :refs="'WHERE_T_FIND_US'"
                                     :err="err.WHERE_T_FIND_US"
                                     v-model="forSend.WHERE_T_FIND_US"></fmSelectRef>
                        <!--                        <fmSelectRef :refs="'EXPER_RADIO'"-->
                        <!--                                     :err="err.EXPER_RADIO"-->
                        <!--                                     v-model="forSend.EXPER_RADIO"></fmSelectRef>-->
                    </div>
                    <div class='flex'>
                        <fmSelectRef :refs="'TYPE_OF_ORGANIZATION'"
                                     :err="err.TYPE_OF_ORGANIZATION"
                                     v-model="forSend.TYPE_OF_ORGANIZATION"></fmSelectRef>
                        <fmSelectRef :refs="'TYPE_OF_ACTIVITY'"
                                     :err="err.TYPE_OF_ACTIVITY"
                                     v-model="forSend.TYPE_OF_ACTIVITY"></fmSelectRef>
                    </div>
                    <div class='flex'>
                        <!--                        <fmSelectRef :refs="'EDUCATION'"-->
                        <!--                                     :err="err.EDUCATION"-->
                        <!--                                     v-model="forSend.EDUCATION"></fmSelectRef>-->
                        <!--                        <fmSelectRef :refs="'MARITAL_STATUS'"-->
                        <!--                                     :err="err.MARITAL_STATUS"-->
                        <!--                                     v-model="forSend.MARITAL_STATUS"></fmSelectRef>-->
                    </div>
                    <!--                  <fmSelectRef class='long' :refs="'WHERE_T_FIND_US'"-->
                    <!--                               :err="err.WHERE_T_FIND_US"-->
                    <!--                               v-model="forSend.WHERE_T_FIND_US"></fmSelectRef>-->


                </div>
            </div>
                <div class="layoutFooter btnIsset" v-if="formStep == 2">

                    <button class="submitButton" v-on:click.prevent="fromStep(2)"
                    >Продолжить оформление
                    </button>
            </div>
        </div>

                        <div class="layoutBorder"
                             v-if="formStatus == 0 && formStep >= 3"
                             ref="formAlign"
                             v-bind:class="{readOnly:formStep > 3}"
                        >
                            <div class="layoutHeader">
                                <h4>Сделайте несколько фотографий</h4>
                                <p v-if="formErrMsg.length > 0" class="formGlobalErr">{{formErrMsg}}</p>
                            </div>
                            <div class="layoutBody">
                                <div id="fotowrap">
                                    <div>
                                        <label for="passport1" class="fotolabel">
                                            <p>Главный разворот паспорта</p>
                                            <p class="errMsg" v-if="err.passport1.length > 0">Не корректное фото, попробуйте еще
                                                раз.</p>
                                            <img :src="foto.passport1" alt="" style="max-width: 100%;">
                                            <imageInput v-on:imageData="saveImage" :target="'passport1'"></imageInput>
                                        </label>
                                        <label for=""></label>
<!--                                        <label for="passport2" class="fotolabel">-->
<!--                                            <p>Разворот с регистрацией</p>-->
<!--                                            <p class="errMsg" v-if="err.passport2.length > 0">Не корректное фото, попробуйте еще-->
<!--                                                раз.</p>-->
<!--                                            <img :src="foto.passport2" alt="" style="max-width: 100%;">-->
<!--                                            <imageInput v-on:imageData="saveImage" :target="'passport2'"></imageInput>-->
<!--                                        </label>-->

                                    </div>
<!--                                    <div>-->
<!--                                        <label for="passport3" class="fotolabel">-->
<!--                                            <p>Сведения о ранее выданном паспорте(разворот 18-19стр)</p>-->
<!--                                            <p class="errMsg" v-if="err.passport3.length > 0">Не корректное фото, попробуйте еще-->
<!--                                                раз.</p>-->
<!--                                            <img :src="foto.passport3" alt="" style="max-width: 100%;">-->
<!--                                            <imageInput v-on:imageData="saveImage" :target="'passport3'"></imageInput>-->
<!--                                        </label>-->
<!--                                        <label for="selfImage" class="fotolabel">-->
<!--                                            <p>Фото портрет в домашней обстановке</p>-->
<!--                                            <p class="errMsg" v-if="err.selfImage.length > 0">Не корректное фото, попробуйте еще-->
<!--                                                раз.</p>-->
<!--                                            <img :src="foto.selfImage" alt="" style="max-width: 100%;">-->
<!--                                            <imageInput v-on:imageData="saveImage" :target="'selfImage'"></imageInput>-->
<!--                                        </label>-->

<!--                                    </div>-->
                                </div>
                            </div>
                            <div class="layoutFooter btnIsset" v-if="formStep == 3">
                                <button class="submitButton" v-on:click.prevent="fromStep(3)">Продолжить оформление</button>
                            </div>
                        </div>

            <div class="layoutBorder"
                 v-if="formStatus == 0 && formStep >= 4"
                 ref="formAlign"
                 v-bind:class="{readOnly:formStep > 4}"
            >
            <div class="layoutHeader">
                <h4>Укажите реквизиты банковского счета</h4>
                <p v-if="formErrMsg.length > 0" class="formGlobalErr">{{formErrMsg}}</p>
            </div>
            <div class="layoutBody">
                <div class="inputBox">
                    <div class="autosuggest-container">
                        <vue-autosuggest
                          class="inputBox"
                          v-model="forSend.bankInfo.value"
                          :suggestions="suggestions"
                          @selected="onSelected"
                          :get-suggestion-value="getSuggestionValue"
                          :input-props="{id:'autosuggest__input'}">
                            <div slot-scope="{suggestion}" class="sugsItem">
                                <p>{{suggestion.item.value}}</p>
                                <p>{{suggestion.item.data.bic}}, {{suggestion.item.data.address.value}}</p>
                            </div>
                        </vue-autosuggest>
                    </div>
                    <label>Банк</label>
                    <!--                    <div v-show="ErrorSelected.length > 0" v-html="ErrorSelected" class="errMsg"></div>-->
                    <i class="stxt">Введите название, БИК, SWIFT или ИНН, и выберите из выпадающего списка</i>
                </div>
                <div id="BankAlign">
                    <div>
                        <baseInput
                          :label="'БИК'"
                          v-model="forSend.bankInfo.data.bic"
                          :errMsg="err.bic"
                        ></baseInput>
                        <baseInput
                          :label="'ИНН'"
                          v-model="forSend.bankInfo.data.inn"
                          :errMsg="err.Inn"
                        ></baseInput>
                    </div>
                    <div>
                        <baseInput
                          :label="'КПП'"
                          v-model="forSend.bankInfo.data.kpp"
                          :errMsg="err.kpp"
                        ></baseInput>
                        <baseInput
                          :label="'Корреспондентский счет в ЦБ РФ'"
                          v-model="forSend.bankInfo.data.correspondent_account"
                          :errMsg="err.correspondent_account"
                        ></baseInput>
                    </div>
                    <div>
                        <baseInput
                          :label="'Номер счета'"
                          v-model="forSend.bankInfo.AccountNumber"
                          :errMsg="err.AccountNumber"
                        ></baseInput>
                        <p>Получатель платежа: {{forSend.FIO}}</p>
                    </div>
                </div>
            </div>
            <div class="layoutFooter btnIsset">
                <button class="submitButton" v-on:click.prevent="confirmForm">Продолжить оформление</button>
            </div>
        </div>

            <div class="layoutBorder" v-if="formStatus == 1">
                <div class="layoutHeader">
                    <h4>Обработка данных: {{progress}} %</h4>
                </div>
                <div class="layoutBody">
                    <loader></loader>
                </div>
            </div>

            <div class="layoutBorder" v-if="formStatus == 2">
                <div class="layoutHeader">
                    <h4>lorem</h4>
                </div>
                <div class="layoutBody">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa doloremque ea expedita iusto
                        officiis
                        recusandae? Autem est nemo nostrum sequi voluptatem voluptatum. Sequi similique, tenetur? Eius
                        iure
                        placeat saepe veritatis?</p>
                </div>
            </div>

            <div class="layoutBorder" v-if="formStatus == 3">
                <div class="layoutHeader">
                    <h4>Подписание договора</h4>
                </div>
                <div class="layoutBody">
                    <div id="dealInfo">
                        <div>
                            <p>{{dealInfo.paymentDate | timeFromXtoDateView}}</p>
                            <p>Дата платежа</p>
                        </div>
                        <div>
                            <p>{{dealInfo.paymentTerm}} дней</p>
                            <p>Срок займа</p>
                        </div>
                        <div>
                            <p>{{forSend.selectedSumm | moneyIntlFormat}}</p>
                            <p>Сумма займа</p>
                        </div>
                    </div>
                    <div id="isAgree">
                        <!--                        <checkBox-->
                        <!--                          :label="'Я согласен/на и ознакомлен/на со следующим:'"-->
                        <!--                          :name="'isAgree'"-->
                        <!--                            v-model="dealInfo.isAgree"-->
                        <!--                        ></checkBox>-->
                        <div>
                            <input type="checkbox"
                                   v-model="dealInfo.isAgree">
                            <p v-bind:class="{errMsg:!dealInfo.isAgree}">Я согласен/на и ознакомлен/на со следующим:</p>
                        </div>
                        <!--                        <checkBox-->
                        <!--                          :label="'Общие условия договора микрозайма с ООО МКК ФИНМОЛЛ'"-->
                        <!--                          :name="'isGeneralConditions'"-->
                        <!--                          v-model="dealInfo.isGeneralConditions"-->
                        <!--                          ref="isAgree1"-->
                        <!--                        ></checkBox>-->
                        <div>
                            <input type="checkbox"
                                   v-model="dealInfo.isGeneralConditions">
                            <p>Общие условия договора микрозайма с ООО МКК ФИНМОЛЛ</p>
                        </div>
                        <a
                          href="https://finmoll.ru/documents/2021.03.23_%D0%BE%D0%B1%D1%89%D0%B8%D0%B5_%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%D0%B0_%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D0%B7%D0%B0%D0%B9%D0%BC%D0%B0.pdf"
                          target="_blank">Открыть документ</a>
                        <!--                        <checkBox-->
                        <!--                          :label="'Правила предоставления микрозаймов ООО МКК ФИНМОЛЛ'"-->
                        <!--                          :name="'isGeneralConditions'"-->
                        <!--                          v-model="dealInfo.isTermsOfProvision"-->
                        <!--                          ref="isAgree2"-->
                        <!--                        ></checkBox>-->
                        <div>
                            <input type="checkbox"
                                   v-model="dealInfo.isTermsOfProvision">
                            <p>Правила предоставления микрозаймов ООО МКК ФИНМОЛЛ</p>
                        </div>
                        <a
                          href="https://finmoll.ru/documents/2021.02.12_%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0_%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D0%B7%D0%B0%D0%B9%D0%BC%D0%BE%D0%B2.pdf"
                          target="_blank">Открыть документ</a><br/>

                        <!--                        <checkBox-->
                        <!--                          :label="'Информация об условиях предоставления, использование и возврата потребительского микрозайма ООО МКК ФИНМОЛЛ'"-->
                        <!--                          :name="'isGeneralConditions'"-->
                        <!--                          v-model="dealInfo.isInformationAboutConditions"-->
                        <!--                          ref="isAgree3"-->
                        <!--                        ></checkBox>-->
                        <!--                        <div>-->
                        <!--                            <input type="checkbox"-->
                        <!--                                   v-model="dealInfo.isInformationAboutConditions">-->
                        <!--                            <p>Информация об условиях предоставления, использование и возврата потребительского микрозайма ООО МКК ФИНМОЛЛ</p>-->
                        <!--                        </div>-->
                        <!--                        <a-->
                        <!--                          href="https://finmoll.ru/documents/2021.03.31_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F_%D0%BE%D0%B1_%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%D1%85_%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D0%B7%D0%B0%D0%B9%D0%BC%D0%BE%D0%B2.pdf"-->
                        <!--                          target="_blank">Открыть документ</a>-->
                        <!--                        <checkBox-->
                        <!--                          :label="'Заявление на предоставление займа'"-->
                        <!--                          :name="'isAgreeRequest'"-->
                        <!--                          v-model="dealInfo.isAgreeRequest"-->
                        <!--                          ref="isAgree4"-->
                        <!--                        ></checkBox>-->
                        <div>
                            <input type="checkbox"
                                   v-model="dealInfo.isAgreeRequest">
                            <p>Заявление на предоставление займа</p>
                        </div>
                        <a :href="'/public/pdlForm?request=' + GUUID" target="_blank">Открыть документ</a>
                        <!--                        <checkBox-->
                        <!--                          :label="'Индивидуальные условия'"-->
                        <!--                          :name="'isAgreeIU'"-->
                        <!--                          v-model="dealInfo.isAgreeIU"-->
                        <!--                          ref="isAgree5"-->
                        <!--                        ></checkBox>-->
                        <div>
                            <input type="checkbox"
                                   v-model="dealInfo.isAgreeIU">
                            <p>Индивидуальные условия</p>
                        </div>
                        <a :href="'/public/pdl_IU?request=' + GUUID" target="_blank">Открыть документ</a>
                    </div>
                </div>
                <div class="layoutFooter btnIsset">
                    <div id="confCodeWrapAlign">
                        <div id="confCodeWrap">
                            <p>Введите код из смс</p>
                            <baseInput
                              :label="'Код подтверждения'"
                              v-model="dealInfo.smsConfCode"
                            ></baseInput>
                            <a href="#">Выслать код повторно</a>
                        </div>
                    </div>
                    <button class="submitButton" v-on:click.prevent="finishPdl"
                            v-bind:class="{active:!dealInfo.isAgree}">Подписать
                        договор</button>
                </div>
            </div>
    </div>
    </div>
</template>

<script>
    import {VueAutosuggest} from "vue-autosuggest";
export default {
    name: "loanEnding",
    components:{
        rangePeriod: () => import('@/views/pdl/rangePeriod'),
        fmSelectRef: () => import('@/components/Form/element/fmSelectRef'),
        baseInput: () => import('@/components/Form/element/baseinput'),
        imageInput: () => import('@/components/formElement/imageInput'),
        loader: () => import('@/components/other/loader'),
        range: () => import('@/components/Form/element/range'),
        fmDadataBank: () => import('@/components/Form/element/fmSelectDadataBank'),
        checkBox:()=>import('@/components/Form/element/CheckBox'),
        VueAutosuggest
    },
    data() {
        return {
            forSend: {
                insurance: false,
                EDUCATION: '',
                EXPER_RADIO: '',
                TYPE_OF_ORGANIZATION: '',
                TYPE_OF_ACTIVITY: '',
                TYPE_OF_EMPLOYMENT: '',
                MARITAL_STATUS: '',
                WHERE_T_FIND_US: '',
                ProductType:'P',
                selectedSumm: 0,
                Inn: '',
                FIO: '',
                bankInfo: {
                    value: '',
                    data: {
                        bic: '',
                        inn:'',
                        kpp:'',
                        correspondent_account:'',
                    },
                    AccountNumber:'',
                }
            },
            err: {
                EDUCATION: '',
                EXPER_RADIO: '',
                TYPE_OF_ORGANIZATION: '',
                TYPE_OF_ACTIVITY: '',
                TYPE_OF_EMPLOYMENT: '',
                MARITAL_STATUS: '',
                WHERE_T_FIND_US: '',
                passport1: '',
                passport2: '',
                passport3: '',
                selfImage: '',
                BankAccount: '',
                Inn: '',
                AccountNumber:'',
                correspondent_account:'',
                kpp:'',
                innBank:'',
                bic:'',
            },
            foto: {
                passport1: '',
                // passport2: '',
                // passport3: '',
                // selfImage: ''
            },
            fotoID:{
                passport1: '',
            },
            dealInfo: {
                paymentDate: '',
                paymentTerm: '',
                paymentSumm: '',
                smsConfCode:'',
                confCodeCorrect:false,
                isAgree:false,
                isAgreeRequest:false,
                isAgreeIU:false,
                isGeneralConditions: false,
                isTermsOfProvision: false,
                isInformationAboutConditions: false,
                pod_ft: true,
                pod_ft1: true,
                pod_ft2: true,
                pod_ft3: true,
                pod_ft4: true,
                pod_ft5: true,
            },
            isLoading: true,
            requestGuid: '',
            iterationSave:0,
            formStatus: 0,
            formStep: 0,
            formErrMsg: '',
            demand: 0,
            GUUID :'',
            pdlLimit: 15000,
            loading: true,
            suggestions: [
                {
                    data: []
                }
            ],
            psk: [],
        }
    },
    methods: {
        insuranceOff() {
            this.$palantirLnd.trackWebAction(105, '', localStorage.getItem('sessionLnd'));
            this.fromStep(0);
            this.forSend.insurance = false;
        },
        checkDigit(inn, coefficients) {
            let n = 0;
            for (let i in coefficients) {
                n += coefficients[i] * inn[i];
            }
            return parseInt(n % 11 % 10);
        },
        validateInn(inn) {
            let result = false;
            if (typeof inn === 'number') {
                inn = inn.toString();
            } else if (typeof inn !== 'string') {
                inn = '';
            }

            if (!inn.length) {
                this.err.Inn = 'ИНН пуст';
            } else if (/[^0-9]/.test(inn)) {
                this.err.Inn = 'ИНН может состоять только из цифр';
            } else if ([10, 12].indexOf(inn.length) === -1) {
                this.err.Inn = 'ИНН может состоять только из 10 или 12 цифр';
            } else {
                switch (inn.length) {
                    case 10:
                        var n10 = this.checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
                        if (n10 === parseInt(inn[9], 10)) {
                            result = true;
                            this.err.Inn ='';
                        }
                        break;
                    case 12:
                        var n11 = this.checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                        var n12 = this.checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                        if ((n11 === parseInt(inn[10], 10)) && (n12 === parseInt(inn[11], 10))) {
                            result = true;
                            this.err.Inn ='';
                        }
                        break;
                }
                if (!result) {
                    this.err.Inn = 'ИНН не корректный';
                }
            }
            return result;
        },
        validateBik() {

            let result = false;
            let bik = this.forSend.bankInfo.data.bic;
            if (typeof bik === 'number') {
                bik = bik.toString();
            } else if (typeof bik !== 'string') {
                bik = '';
            }

            if (!bik.length) {
                this.err.bic = 'БИК пуст';
            } else if (/[^0-9]/.test(bik)) {
                this.err.bic = 'БИК может состоять только из цифр';
            } else if (bik.length !== 9) {
                this.err.bic = 'БИК может состоять только из 9 цифр';
            } else {
                result = true;
                this.err.bic = '';
            }
            return result;
        },
        validateKpp() {
        let result = false;
        let kpp = this.forSend.bankInfo.data.kpp;
        if (typeof kpp === 'number') {
            kpp = kpp.toString();
        } else if (typeof kpp !== 'string') {
            kpp = '';
        }
        if (!kpp.length) {
            this.err.kpp = 'КПП пуст';
        } else if (kpp.length !== 9) {
            this.err.kpp = 'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита)';
        } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
            this.err.kpp = 'Неправильный формат КПП';
        } else {
            this.err.kpp = '';
            result = true;
        }
        return result;
    },
        validateRs() {
            let result = false;
            let rs = this.forSend.bankInfo.AccountNumber;
                if (typeof rs === 'number') {
                    rs = rs.toString();
                } else if (typeof rs !== 'string') {
                    rs = '';
                }
                if (!rs.length) {
                    this.err.AccountNumber = 'Номер счета пуст';
                } else if (/[^0-9]/.test(rs)) {
                    this.err.AccountNumber = 'Номер счета может состоять только из цифр';
                } else if (rs.length !== 20) {
                    this.err.AccountNumber = 'Номер счета может состоять только из 20 цифр';
                } else {
                    let bikRs = this.forSend.bankInfo.data.bic.toString().slice(-3) + rs;
                    let checksum = 0;
                    let coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
                    for (let i in coefficients) {
                        checksum += coefficients[i] * (bikRs[i] % 10);
                    }
                    if (checksum % 10 === 0) {
                        result = true;
                        this.err.AccountNumber = '';
                    } else {
                        // this.err.AccountNumber = 'Неправильное контрольное число';
                        this.err.AccountNumber = 'Неправильный номер или БИК';
                    }
                }

            return result;
        },
        validateKs() {
            let result = false;
            let ks =  this.forSend.bankInfo.data.correspondent_account;
                if (typeof ks === 'number') {
                    ks = ks.toString();
                } else if (typeof ks !== 'string') {
                    ks = '';
                }
                if (!ks.length) {
                    this.err.correspondent_account = 'К/С пуст';
                } else if (/[^0-9]/.test(ks)) {
                    this.err.correspondent_account  = 'К/С может состоять только из цифр';
                } else if (ks.length !== 20) {
                    this.err.correspondent_account  = 'К/С может состоять только из 20 цифр';
                } else {
                    let bikKs = '0' + this.forSend.bankInfo.data.bic.toString().slice(4, 6) + ks;
                    let checksum = 0;
                    let coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
                    for (let i in coefficients) {
                        checksum += coefficients[i] * (bikKs[i] % 10);
                    }
                    if (checksum % 10 === 0) {
                        result = true;
                    } else {
                        this.err.correspondent_account = 'Неправильный номер или БИК';
                    }
                }
            return result;
        },
        fromStep(currentStep) {
            switch (currentStep) {
                case 0:

                    if (this.dealInfo.pod_ft) {
                        //если зажали галочку на все согласны
                        this.$palantirLnd.trackWebAction(106, '', localStorage.getItem('sessionLnd'));
                        this.forSend.insurance = false;
                    } else {
                        return false;
                    }
                    break;
                case 1:
                    if (this.validateInn(this.forSend.Inn)) {
                        this.err.Inn = '';
                        this.$palantirLnd.trackWebAction(107, '', localStorage.getItem('sessionLnd'));
                    } else {
                        this.$palantirLnd.trackWebAction(108, '', localStorage.getItem('sessionLnd'));
                        return false;
                    }
                    break;
                case 2:
                    let errCount = 0;
                    // if (this.forSend.EDUCATION == '') {
                    //     errCount += 1;
                    //     this.err.EDUCATION = 'Обязательное поле';
                    // } else {
                    //     this.err.EDUCATION = '';
                    // }
                    // if (this.forSend.EXPER_RADIO == '') {
                    //     errCount += 1;
                    //     this.err.EXPER_RADIO = 'Обязательное поле';
                    // } else {
                    //     this.err.EXPER_RADIO = '';
                    // }
                    if (this.forSend.TYPE_OF_ORGANIZATION == '') {
                        errCount += 1;
                        this.err.TYPE_OF_ORGANIZATION = 'Обязательное поле';
                    } else {
                        this.err.TYPE_OF_ORGANIZATION = '';
                    }
                    if (this.forSend.TYPE_OF_ACTIVITY == '') {
                        errCount += 1;
                        this.err.TYPE_OF_ACTIVITY = 'Обязательное поле';
                    } else {
                        this.err.TYPE_OF_ACTIVITY = '';
                    }
                    if (this.forSend.TYPE_OF_EMPLOYMENT == '') {
                        errCount += 1;
                        this.err.TYPE_OF_EMPLOYMENT = 'Обязательное поле';
                    } else {
                        this.err.TYPE_OF_EMPLOYMENT = '';
                    }
                    // if (this.forSend.MARITAL_STATUS == '') {
                    //     errCount += 1;
                    //     this.err.MARITAL_STATUS = 'Обязательное поле';
                    // } else {
                    //     this.err.MARITAL_STATUS = '';
                    // }
                    if (this.forSend.WHERE_T_FIND_US == '') {
                        errCount += 1;
                        this.err.WHERE_T_FIND_US = 'Обязательное поле';
                    } else {
                        this.err.WHERE_T_FIND_US = '';
                    }

                    if (errCount > 0) {
                        this.$palantirLnd.trackWebAction(109, '', localStorage.getItem('sessionLnd'));
                        return false;
                    }
                    this.$palantirLnd.trackWebAction(110, '', localStorage.getItem('sessionLnd'));
                    // this.formStep +=1 ;
                    break;
                case 3:

                    if (this.checkFoto() > 0) {
                        this.$palantirLnd.trackWebAction(111, '', localStorage.getItem('sessionLnd'));
                        return false;
                    } else {
                        this.$palantirLnd.trackWebAction(112, '', localStorage.getItem('sessionLnd'));
                    }
                    break;
                default:
                    console.log(currentStep);
                    break;
            }
            // localStorage.setItem('preSaveForm', JSON.stringify(this.forSend));
            this.formStep +=1 ;
        },
        saveImage(returnObg) {
            //пишет base64 из компонента в дата
            this.foto[returnObg.target] = returnObg.value;
        },
        sendFile(target) {
            //пытаемся запихнуть фото в сервис
            return new Promise((resolve, reject) => {
                if (this.foto[target] == '') {
                    this.foto[target + 'Err'] = 'err';
                    reject('err');
                    return false;
                }

                this.$myApi.singleRequest({
                    action: 'saveFile',
                    file: this.foto[target],
                    requestGuid: 'pdl' + this.demand,
                    target: target,
                }).then(res => {
                    this.iterationSave += 1;
                    this.fotoID[target] = res.file_id;
                    this.$palantirLnd.trackWebAction(150, 'foto id=' +  res.file_id, localStorage.getItem('sessionLnd'));
                    resolve();
                }).catch(res => {
                    this.err[target] = 'err';
                    this.$palantirLnd.trackWebAction(151, JSON.stringify(res), localStorage.getItem('sessionLnd'));
                    reject(res);
                })
            });
        },
        saveData() {
            return new Promise((resolve, reject) => {
                this.$external.universalRequest({
                  action: 'saveAnketaPDL',
                  externalType: 'zita',
                  demand: this.demand,
                  data:{...this.fotoID, ...this.forSend}
                }).then(res => {
                    resolve(res);
                }).catch(res => {
                  if ( res.errorCode > 0 || res.errorCode !== undefined ) {
                    alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
                  }
                    reject(res);
                });
            });
        },
        sendPassport1() {
            return this.sendFile('passport1');
        },
        sendPassport2() {
            return this.sendFile('passport2');
        },
        sendPassport3() {
            return this.sendFile('passport3');
        },
        sendSelfi() {
            return this.sendFile('selfImage');
        },
        sendBank() {
            return this.sendFile('BankAccount');
        },
        checkFoto() {
            let emptyField = 0;
            // for (let item in this.forSend) {
            //     if (this.forSend[item].length == 0) {
            //         this.err[item] = 'Обязательное поле';
            //         emptyField += 1;
            //     }
            // }
            for (let item in this.foto) {
                if (this.foto[item].length == 0) {
                    this.err[item] = 'Обязательное поле';
                    emptyField += 1;
                }
            }
            if (emptyField > 0) {
                this.formStatus = 0;
                this.formErrMsg = 'В форме есть ошибки, исправте их и нажмите "продолжить оформление"';
                window.scrollTo({
                    left: 0,
                    top: this.$refs.formAlign.offsetTop,
                    behavior: 'smooth'
                });
            }
            return emptyField;
        },
        checkBank() {
            let errCount = 0;
            if (!this.validateBik()) errCount += 1;
            if (errCount == 0) {
                if (!this.validateKpp()) errCount += 1;
                if (!this.validateInn(this.forSend.bankInfo.data.inn)) errCount += 1;
                if (!this.validateKs()) errCount += 1;
                if (!this.validateRs()) errCount += 1;
            }
            if (errCount > 0){
                this.$palantirLnd.trackWebAction(113, '', localStorage.getItem('sessionLnd'));
            }
            return errCount == 0;
        },
        confirmForm() {
            if (this.checkBank()) {
                this.formStatus = 1;
                //по другому не смог запустить их строго по очереди
                this.sendPassport1()
                //     .then(this.sendPassport2)
                //     .then(this.sendPassport3)
                //     .then(this.sendSelfi)
                .then(this.saveData)
                // this.saveData()
                .then((res) => {
                    if (res.Success == 1) {
                        this.GUUID = res.GUUID;
                        this.formStatus = 3;
                        this.dealInfo.paymentDate = res.PayDt;
                        this.dealInfo.paymentTerm = res.period;
                        this.$palantirLnd.trackWebAction(152, '', localStorage.getItem('sessionLnd'));
                    } else {
                        this.$palantirLnd.trackWebAction(153, JSON.stringify(res), localStorage.getItem('sessionLnd'));
                    }
                })
                .catch(res => {
                    let result = this.$myApi.parseError(res);
                    this.formErrMsg = result.ExecutionErrorMessage;
                    this.formStatus = 0;
                    this.$palantirLnd.trackWebAction(154, JSON.stringify(res), localStorage.getItem('sessionLnd'));
                });
            }else {
                this.$palantirLnd.trackWebAction(113, '', localStorage.getItem('sessionLnd'));
            }


        },
        checkDesign() {
            this.$palantirLnd.trackWebAction(102, '', localStorage.getItem('sessionLnd'));
            this.$external.universalRequest({
                action: 'CheckDecisionByID',
                typeRequest: 'loan',
              externalType: 'zita',
                demand: this.demand
            }).then((res) => {
                this.loading = false;
                this.$refs.range.kostilValue
                  = this.forSend.selectedSumm
                  = this.pdlLimit
                  = (res.pdlLimit != undefined) ? res.pdlLimit : 0;
                this.forSend.Inn = res.Inn;
                this.forSend.FIO = res.FIO;
                this.$palantirLnd.trackWebAction(103, '', localStorage.getItem('sessionLnd'));
            }).catch((res) => {
              if ( res.errorCode > 0 || res.errorCode !== undefined ) {
                alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
              }
                this.loading = false;
                this.$palantirLnd.trackWebAction(104, JSON.stringify(res), localStorage.getItem('sessionLnd'));
            }).finally(() => {
            })
        },
        onSelected(item) {
            this.forSend.bankInfo = item.item;
        },
        getSuggestionValue(suggestion) {
            return suggestion.item.value;
        },
        finishPdl(){
            if (this.dealInfo.isAgreeIU
              && this.dealInfo.isAgreeRequest
              && this.dealInfo.isAgree
              && this.dealInfo.isGeneralConditions
              && this.dealInfo.isTermsOfProvision
              && this.dealInfo.isInformationAboutConditions
            ) {
                this.loading = true;
                this.$external.universalRequest({
                    action: 'pdl_CheckCode',
                  externalType: 'zita',
                    Code:this.dealInfo.smsConfCode,
                    demand:this.demand,
                }).then((res)=>{
                    console.log(res);
                    this.$palantirLnd.trackWebAction(155, JSON.stringify(res), localStorage.getItem('sessionLnd'));
                    this.$store.dispatch('resetState');
                    setTimeout(()=>{
                        this.$router.push('/');
                    }, 500);
                }).catch((res)=>{
                  if ( res.errorCode > 0 || res.errorCode !== undefined ) {
                    alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
                  }
                    this.$palantirLnd.trackWebAction(156, JSON.stringify(res), localStorage.getItem('sessionLnd'));
                }).finally(()=>{
                    this.loading = false;
                })
            } else {
                this.$palantirLnd.trackWebAction(157, '', localStorage.getItem('sessionLnd'));
                alert('Для продолжения, подтвердите достоверность введенных данных и согласия')
            }
        }
    },
    computed: {
        progress() {
            return Math.round(this.iterationSave / 7 * 100);
        },
        minLimit() {
            if (this.psk.length == 0) return 0;
            let minValue = this.pdlLimit;
            this.psk.forEach(item => {
                if (parseInt(item.Amount, 10) < minValue) {
                    minValue = parseInt(item.Amount, 10)
                }
            });
            return minValue;
        },
        computedPay() {
            if (this.psk.length == 0) return {FixedPay: 0}
            let result = this.psk.find((item) => {
                return parseInt(item.Amount, 10) == this.forSend.selectedSumm &&
                        item.ProductType == this.forSend.ProductType
            });
            if (result == undefined || result.FixedPay == undefined) return {FixedPay: 0}
            return result;
        }
    },
    watch: {
        'dealInfo.isAgreeIU'(value) {
            if (!value) {
                this.dealInfo.isAgree = false;
            }
        },
        'dealInfo.isAgreeRequest'(value) {
            if (!value) {
                this.dealInfo.isAgree = false;
            }
        },
        'dealInfo.isGeneralConditions'(value) {
            if (!value) {
                this.dealInfo.isAgree = false;
            }
        }, 'dealInfo.isTermsOfProvision'(value) {
            if (!value) {
                this.dealInfo.isAgree = false;
            }
        }, 'dealInfo.isInformationAboutConditions'(value) {
            if (!value) {
                this.dealInfo.isAgree = false;
            }
        },
        //   && this.dealInfo.
        //   && this.dealInfo.
        //   && this.dealInfo.
        //   && this.dealInfo.
        //   && this.dealInfo.
        'dealInfo.isAgree'(value) {
            if (value == true) {
                this.dealInfo.isAgreeIU =
                  this.dealInfo.isAgreeRequest =
                    this.dealInfo.isGeneralConditions =
                      this.dealInfo.isTermsOfProvision =
                        this.dealInfo.isInformationAboutConditions = true;
            } else {
                this.dealInfo.isAgreeIU =
                  this.dealInfo.isAgreeRequest =
                    this.dealInfo.isGeneralConditions =
                      this.dealInfo.isTermsOfProvision =
                        this.dealInfo.isInformationAboutConditions = false;
            }
        },
        'forSend.bankInfo.value'(value) {
            if (value.length > 3) {
                this.$myApi.singleRequest({action: 'Dadata_getBank', query: value})
                .then((res) => {
                    this.suggestions[0].data = res.suggestions;
                })
            }
        },
        'dealInfo.pod_ft'(value) {
            if (value) {
                this.dealInfo.pod_ft1 =
                  this.dealInfo.pod_ft2 =
                    this.dealInfo.pod_ft3 =
                      this.dealInfo.pod_ft4 =
                        this.dealInfo.pod_ft5 = true;
            } else {
                this.dealInfo.pod_ft1 =
                  this.dealInfo.pod_ft2 =
                    this.dealInfo.pod_ft3 =
                      this.dealInfo.pod_ft4 =
                        this.dealInfo.pod_ft5 = false;
            }
        },
        'dealInfo.pod_ft1'(value) {
            if (!value) {
                this.dealInfo.pod_ft = false;
            }
        },
        'dealInfo.pod_ft2'(value) {
            if (!value) {
                this.dealInfo.pod_ft = false;
            }
        },
        'dealInfo.pod_ft3'(value) {
            if (!value) {
                this.dealInfo.pod_ft = false;
            }
        },
        'dealInfo.pod_ft4'(value) {
            if (!value) {
                this.dealInfo.pod_ft = false;
            }
        },
        'dealInfo.pod_ft5'(value) {
            if (!value) {
                this.dealInfo.pod_ft = false;
            }
        }
    },
    mounted: function () {
        // this.formStatus = 3;
        localStorage.removeItem('redirect');
        this.$palantirLnd.trackWebAction(101, '', localStorage.getItem('sessionLnd'));


        this.demand = parseInt(localStorage.getItem('demand'), 10);

        // if (localStorage.getItem('preSaveForm') != undefined) {
        // this.forSend = JSON.parse(localStorage.getItem('preSaveForm'));
        // }

        this.$myApi.singleRequest({
            action: 'getPdlPsk',
            type: 'pdl'
        }).then((res) => {
            this.psk = res.sort(function (a, b) {
                return a.Amount - b.Amount;
            });
        }).catch((res) => {
            console.log(res);
        });
        this.$myApi.singleRequest({
            "action":"loanForm",
            "type":"getRef"
        }).then((res)=>{
            this.$store.dispatch('saveRef', res);
        }).catch((res)=>{
            console.log(res)
        });
        this.checkDesign();
    },
  }
</script>

<style scoped>
    .flex{justify-content: space-between}
    .fotolabel{border:1px solid #0f5740;border-radius: 4px;padding: 4px;display: block;margin: 2vw 0;}
    #formGlobalErr {color: coral}

    #formStep1 {}
    #formStep1 .subBox {}
    .bigNumber {font-weight: bold}
    #formStep1 .subBox:last-child {display: flex;align-items: center;justify-content: space-evenly;flex-wrap: wrap}
    #formStep1 .subBox:first-child > p {display: flex;align-items: center;justify-content: space-between}
    #formStep1 .subBox:first-child .limitRange {display: flex;align-items: center;justify-content: space-between}
    #withOutInsurance {}
    #withOutInsurance p {margin: 0;}
    .readOnly {pointer-events: none;opacity: 0.3}
    .sugsItem {}
    .sugsItem p:first-child {}
    .sugsItem p:last-child {}
    #dealInfo{display: flex;align-items: center;justify-content: space-around;background: white;width: 100%;margin: 0 auto;padding: 3.4vw 0;border: 1px solid #c2c2c2;border-radius: 5px}
    #dealInfo > div{width: 30%;text-align: center}
    /deep/ #isAgree label{justify-content: space-between;
    text-align: end;
    height: 14vw;}

    #isAgree{width: 100%;margin: 7vw auto; }
    .inputBoxWrap{width:100%;}
    .long{width: 100%;}

    ul li div {display: flex;align-items: center;margin: 10px 0;text-align: left}
    ul li div input {margin-right: 10px}
    ul li div p {margin: 0;padding: 0;}
    ul li > ul {margin-left: 30px;}
    ul li > ul > li {list-style: circle}
    /deep/ #asp .visualCheckBox {border: 1px solid #c2c2c2;}
    /deep/ #asp .visualCheckBox.active {stroke: #43AC35}
    #isAgree > div {display: flex;align-items: center;margin: 10px 0;text-align: left}
    #isAgree > div > input {margin-right: 10px}
    #isAgree > div > p {margin: 0;padding: 0;}
    #pdlPeriod{}
    #pdlPeriod > div{margin-bottom: 2vw;}

    @media screen and (min-width: 760px) {
        .flex{display:flex;}
        #fotowrap {display: flex;align-items: flex-start;justify-content: space-between;flex-wrap: wrap;}
        #fotowrap > div {width: 49%;}
        .fotolabel{margin: 2vw 0; min-height: 16vw;}
        .fotolabel > p{height: 3vw;}
        #formStep1 {display: flex;align-items: stretch;justify-content: space-between;}
        #formStep1 .subBox:first-child {width: calc(70% - 40px);}
        #formStep1 .subBox:last-child {width: calc(28% - 40px);}
        #withOutInsurance {display: flex;align-items: center;justify-content: flex-end;padding-bottom: 1vw}
        #withOutInsurance p {padding: 0 2.7vw;font-size: 1.2vw}
        #dealInfo{display: flex;align-items: center;justify-content: space-between;background: white;width: 100%;margin: 0 auto;padding: 3.4vw 0;border: 1px solid #c2c2c2;border-radius: 5px}
        #dealInfo > div{width: 30%;text-align: center}
        #isAgree{margin: 2vw auto}
        .inputBoxWrap{width: 49.5%;}
        .long{width: 100%;}
        #BankAlign > div {display: flex;align-items: center;justify-content: space-between;}
        #BankAlign > div > .inputBox {width: 49%}
        #BankAlign > div > p{width: 49%; }
        /deep/ #isAgree label{justify-content: flex-start;height: 3vw;}

    }

    @media screen and (min-width: 1200px){
        .fotolabel{margin: 10px 0; min-height:151px;}
        .bigNumber {font-size: 30px;}
        #withOutInsurance {padding-bottom: 25px}
        #withOutInsurance p {padding: 0 26px 0 0;font-size: 18px}
        .sugsItem {padding: 5px 10px;}
        .sugsItem p {margin: 0;}
        .sugsItem p:first-child {font-size: 14px}
        .sugsItem p:last-child {font-size: 12px;color: #c2c2c2;}

        #dealInfo{display: flex;align-items: center;justify-content: space-between;background: white;width: 70%;margin: 0 auto;padding: 20px 0;border: 1px solid #c2c2c2;border-radius: 5px}
        #dealInfo > div{width: 30%;text-align: center}
        #dealInfo > div > p:first-child{}
        #dealInfo > div > p:last-child{}

        #isAgree{width: 70%;margin: 20px auto}


        #confCodeWrap{width: 220px;text-align: center;position: relative;right: 0;}
        #confCodeWrapAlign{display: flex;align-items: center;justify-content: flex-end;margin: 0 0 30px;}
        /deep/ #isAgree label {justify-content: flex-start;height: auto;}
        /deep/ #isAgree label p {margin: 0 0 0 10px;}
        #isAgree a {margin: 0 0 10px 10px;display: inline-block}
        #pdlPeriod{display: flex;align-items: center}
        #pdlPeriod > div{margin-bottom: 10px;margin-right: 10px;}
    }
    .submitButton.active{background: #c2c2c2}
    #pdlWrap {margin-bottom: 6vh;}
</style>
